<template>
  <div v-if="mounted">
    <v-card>
      <TitleCard>
        <template slot="title">
          Productos para Redfloral
        </template>
        <template slot="subtitle">
          Para el dia {{ general.date | moment('dddd DD') }}
          de
          {{ general.date | moment('MMMM - YYYY') }}
        </template>

        <template slot="button">
          <v-badge left overlap>
            <v-btn color="error" @click="statusActive = 1"> Por hacer / Trabajando </v-btn>
          </v-badge>
          <v-badge left overlap>
            <v-btn color="warning" @click="statusActive = 2"> Finalizados </v-btn>
          </v-badge>
        </template>
      </TitleCard>
      <v-card-text>
        <v-layout v-if="products.length > 0" row wrap>
          <v-flex v-for="(p, i) in products" :key="i" xs12 sm12 md6 xl4>
            <v-layout class="ma-0" row wrap>
              <v-flex xs4>
                <a href="#">
                  <v-img :src="p.product.products_image[0].photo_medium" aspect-ratio="1"></v-img>
                </a>
                <div class="text-xs-center mt-2">
                  <v-avatar>
                    <img :src="p.order.company.detail.avatar" alt="John" />
                  </v-avatar>
                  <p class="body-2 mt-2">{{ p.order.company.detail.name }}</p>
                </div>
              </v-flex>
              <v-flex xs8>
                <h2
                  class="subheading"
                  :title="`${p.product_quantity} x ${p.product_name}`"
                  v-html="`${p.product_quantity > 1 ? p.product_quantity + ' x ' : ''}${p.product_name}`"
                />

                <p v-if="p.quantity > 1" class="body-2 error--text">Cantidad: {{ p.product_quantity }}</p>

                <h3 class="body-2 mt-2">Pedido {{ p.order.id }}</h3>
                <p
                  class="caption mb-0"
                  v-html="`Entre ${p.order.schedule.time_start} y ${p.order.schedule.time_end} hrs`"
                />
                <p class="caption" v-html="p.order.zone.zone.name" />
                <v-alert :value="p.note !== '' && p.note !== null && p.note !== 'null'" color="info" v-html="p.note" />
                <template v-if="p.status_design === 'INACTIVE'">
                  <v-btn
                    class="ma-0 ml-0"
                    :loading="loadingBtn"
                    color="orange white--text"
                    @click="updateDesigner(p.order.id, p.id, 'take')"
                  >
                    Asignar
                  </v-btn>
                </template>
                <template v-else-if="p.status_design === 'WORKING'">
                  <v-btn
                    :loading="loadingBtn"
                    large
                    class="ma-0"
                    color="info"
                    @click="updateDesigner(p.order.id, p.id, 'finish')"
                  >
                    Finalizar
                  </v-btn>
                </template>
                <template v-else-if="p.status_design === 'COMPLETE'">
                  <v-chip label small class="ma-0 mb-2" color="success" text-color="white"> Finalizado </v-chip>
                </template>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout v-else row wrap>
          <v-flex xs12>
            <v-alert :value="true" color="accent" icon="info" outline>
              No hay productos para el dia
              {{ general.date | moment('dddd DD') }}
              de
              {{ general.date | moment('MMMM - YYYY') }}
            </v-alert>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import TitleCard from '../useful/titleCard.vue'
export default {
  name: 'RedFloral',
  components: { TitleCard },
  props: ['date', 'apiKey', 'update'],
  data() {
    return {
      apiUrl: 'https://api.redfloral.cl/api/v1/',
      items: [],
      statusDesign: [
        { id: 'INACTIVE', name: 'POR HACER' },
        { id: 'WORKING', name: 'CONFECCION' },
        { id: 'COMPLETE', name: 'TERMINADO' }
      ],
      statusActive: 1,
      mounted: false,
      loadingBtn: false
    }
  },
  computed: {
    general() {
      return this.$store.getters.getGeneral
    },
    products() {
      const products_ = []
      this.items.forEach(o => {
        o.products.forEach(p => {
          if (this.statusActive === 1 && (p.status_design === 'INACTIVE' || p.status_design === 'WORKING')) {
            const po = p
            p.order = o
            products_.push(po)
          }
          if (this.statusActive === 2 && p.status_design === 'COMPLETE') {
            const po = p
            p.order = o
            products_.push(po)
          }
        })
      })
      return products_
    }
  },
  watch: {
    'general.date': function date() {
      this.getFloral()
    },
    update(e) {
      if (e === true || e === false) {
        this.getFloral()
      }
    }
  },
  mounted() {
    this.getFloral()
  },
  methods: {
    async getFloral() {
      const { apiUrl, apiKey, general } = this
      const response = await fetch(
        `${apiUrl}pedidos/${apiKey}?dateStart=${general.date}&dateEnd=${general.date}&status=1`
      )
      const data = await response.json()
      if (!data.error) {
        this.items = data.rows
        this.mounted = true
      }
    },
    async updateDesigner(order_id, order_detail_id, type_form) {
      this.loadingBtn = true
      const { apiUrl, apiKey } = this
      const url = `${apiUrl}pedidos/${apiKey}`
      const params = {
        tp: 'elaboration',
        order_id,
        order_detail_id,
        type_form
      }
      const options = {
        method: 'PUT',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(params)
      }
      try {
        const response = await fetch(url, options)
        await response.json()
        this.getFloral()
        this.loadingBtn = false
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>
